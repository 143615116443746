require('./src/global-style.css');

exports.onInitialClientRender = () => {
    window.scrollTo(0, 0);
};
exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
      window.scrollTo(0, 0);

      return false;
  }